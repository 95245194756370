import React from 'react'
import { FaJava, FaEthereum, FaReact } from 'react-icons/fa'
import { SiSolidity, SiKubernetes, SiOwasp, SiAmazonaws, SiMicrosoftazure, SiIpfs, SiJirasoftware, SiPython } from 'react-icons/si'

export default function Carousel() {
    return (
        <div>
            <div className='carousel-parent position-relative'>
                <h1 className='position-absolute top-0 start-0 end-0 text-center mt-5'> Tech Toolkit </h1>

                <div className='gallery'>
                    <span style={{ '--i': 1 }}>
                        <FaReact color="cyan" />
                    </span>
                    <span style={{ '--i': 2 }}>
                        <FaJava color="orange" />
                    </span>
                    <span style={{ '--i': 3 }}>
                        <FaEthereum color="grey" />
                    </span>
                    <span style={{ '--i': 4 }}>
                        <SiSolidity color="blue" />
                    </span>
                    <span style={{ '--i': 5 }}>
                        <SiKubernetes color="blue" />
                    </span>
                    <span style={{ '--i': 6 }}>
                        <SiOwasp color="blue" />
                    </span>
                    <span style={{ '--i': 7 }}>
                        <SiAmazonaws color="yellow" />
                    </span>
                    <span style={{ '--i': 8 }}>
                        <SiMicrosoftazure color="blue" />
                    </span>
                    {/* Golang svg*/}
                    <span style={{ '--i': 9 }}>
                        <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                            width="144" height="144"
                            viewBox="0 0 48 48"
                        >
                            <path fill="#ffcc80" d="M35.547 42.431l-3.182-3.182-2.121 2.121 3.182 3.182c.586.586 1.536.586 2.121 0S36.133 43.017 35.547 42.431zM38.547 24.431l-3.182-3.182-2.121 2.121 3.182 3.182c.586.586 1.536.586 2.121 0S39.133 25.017 38.547 24.431zM12.683 42.431l3.182-3.182 2.121 2.121-3.182 3.182c-.586.586-1.536.586-2.121 0S12.097 43.017 12.683 42.431zM9.433 24.431l3.182-3.182 2.121 2.121-3.182 3.182c-.586.586-1.536.586-2.121 0S8.847 25.017 9.433 24.431z"></path><path fill="#4dd0e1" d="M38 8c0-1.933-1.149-3-3.231-3S31 7.567 31 9.5c0 1 1.923 1.5 3 1.5C36.082 11 38 9.933 38 8zM10 8c0-1.933 1.149-3 3.231-3S17 7.567 17 9.5c0 1-1.923 1.5-3 1.5C11.918 11 10 9.933 10 8z"></path><path fill="#424242" d="M35 7A1 1 0 1 0 35 9 1 1 0 1 0 35 7zM13 7A1 1 0 1 0 13 9 1 1 0 1 0 13 7z"></path><path fill="#4dd0e1" d="M37,34c0,4.774-3.219,10-13.31,10C15.568,44,11,38.774,11,34c0-5,1-5.806,1-10c0-4.688,0-7,0-10 c0-4.774,3.076-11,11.69-11S36,6.991,36,13c0,3-0.237,5.453,0,10C36.186,26.562,37,31,37,34z"></path><g><path fill="#f5f5f5" d="M29 6A4 4 0 1 0 29 14 4 4 0 1 0 29 6zM19 6A4 4 0 1 0 19 14 4 4 0 1 0 19 6z"></path></g><g><path fill="#eee" d="M24 20c0 .552.448 1 1 1s1-.448 1-1v-3h-2V20zM22 20c0 .552.448 1 1 1s1-.448 1-1v-3h-2V20z"></path></g><path fill="#ffcc80" d="M26.5,18c-0.412,0-0.653-0.085-1.011-0.205c-0.975-0.328-2.021-0.326-2.996,0.002 C22.138,17.916,21.91,18,21.5,18c-1.334,0-1.5-1-1.5-1.5c0-1.5,1.5-2.5,3-2.5c0.835,0,1.165,0,2,0c1.5,0,3,1,3,2.5 C28,17,27.834,18,26.5,18z"></path><g><path fill="#424242" d="M27 9A1 1 0 1 0 27 11 1 1 0 1 0 27 9zM17 9A1 1 0 1 0 17 11 1 1 0 1 0 17 9zM24 13A2 1 0 1 0 24 15 2 1 0 1 0 24 13z"></path></g>
                        </svg>
                    </span>

                    <span style={{ '--i': 10 }}>
                        <SiIpfs color="#33CBCB" />
                    </span>

                    <span style={{ '--i': 11 }}>
                        <SiJirasoftware color="blue" />
                    </span>
                    <span style={{ '--i': 12 }}>
                        <SiPython color="blue" />
                    </span>

                </div>
            </div>
        </div>
    )
}
