import logo from './logo.svg';
import {Route, Routes, BrowserRouter} from 'react-router-dom'
import Home from './pages/Home'
import Aboutme from './pages/Aboutme'
import Projects from './pages/Projects'
import Footer from './components/Footer';
import './App.css';
import './stylesheets/home.css'
import './stylesheets/header-footer.css'
import './stylesheets/carousel.css'



function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route path='/aboutme' element={<Aboutme/>} />
          <Route path='/projects' element={<Projects/>} />
          {/* <Route path='/workex' element={<WorkEx/>} /> */}
        </Routes>
      </BrowserRouter>

      <Footer/>

    </div>
  );
}

export default App;
