import React from 'react'
import { FaLaptopCode } from 'react-icons/fa'
import { GiThink } from 'react-icons/gi'
import Header from '../components/Header'
import WorkEx from './WorkEx'
import workexlist from './workexdata'
import './aboutMe.css';

function Aboutme() {
    return (
        <div>
            <Header />
            <div className='container'>
                <div className='row flex-with-center mt-5'>
                    <div className='col-md-6 n-box2 p-3'>
                        <div>
                            <h1 className='font-bold'>
                                More about me ...
                            </h1>
                            <p>
                                <div className='font-bold'>So what's my story ?  <GiThink /></div>
                                My journey in Tech began when I started contributing to open source back in my college
                                days. I have enjoyed and learned a lot
                                from the talent across the world in the open source community and at work.
                                through my career I've worked with both startups and big companies.
                            </p>

                            <p>
                                <div className='font-bold'>What's my work-style like? </div>
                                I'm tech agnostic and believe in using the right tool for the right job.
                                Technology is all about choices and knowing the right tool is half the battle sometimes.
                                This is why you'll notice that the word "Stack" gets thrown around a lot.
                                However just code doesn't make a company or an engineering team.
                                Culture has a significant impact in any engineering team. With proper communication and
                                right processes in place, projects can delivered with quality, on-time without compromising the security.
                            </p>

                            <p>
                                <div className='font-bold'>What am I upto these days ??</div>
                                I've led and owned a number of critical projects in the past and currently
                                leading a team to deliver a world class SaaS project to enable our customers
                                to be able to move data from anywhere to anwhere.
                            </p>

                            <p>
                                <div className='font-bold'>A couple of fun projects currently working on?</div>
                                I've been digging into Web3 and have been working on building a Crypto app.
                                So hire me before I become a millionaire :)
                                If all goes well then I should have a fun token coming up ! 
                            </p>

                        </div>
                    </div>

                    <div className='col-md-6 position-relative'>
                        <svg viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
                            <path fill="#0F62FE" d="M43.5,-67.5C56.3,-59.5,66.6,-47.3,75,-33C83.4,-18.7,90,-2.4,89.3,13.9C88.5,30.1,80.4,46.4,68.5,58.3C56.6,70.3,40.9,78,25,80.7C9.1,83.3,-7,80.9,-19.1,73.5C-31.3,66.1,-39.5,53.7,-50,42.7C-60.5,31.7,-73.3,22,-80,8.5C-86.6,-5.1,-87.2,-22.6,-79.2,-34.7C-71.3,-46.8,-55,-53.5,-40.4,-60.7C-25.8,-67.9,-12.9,-75.6,1.2,-77.5C15.4,-79.4,30.7,-75.5,43.5,-67.5Z" transform="translate(100 100)" />
                        </svg>
                        <FaLaptopCode
                            color="white"
                            size='100'
                            className='position-absolute top-50 start-50 translate-middle' />
                    </div>
                </div>
            </div>

            {/* work experiences */}
            <div className='container'>
                <h3 className='font-bold text-center mt-5'>
                    Where have I worked ?
                </h3>
                <div style={{display: 'flex', flexWrap: 'wrap', justifyContent: 'center'}}>
                    {workexlist.map((workex) => {
                        return (
                            <div class="workExItems">
                                <WorkEx data={workex} />
                                    {/* <img src={workex.image} alt="" className='w-100' /> */}
                                    {/* <div className="course-content w-100">
                                        <h3>{workex.title}</h3>
                                        <hr />
                                        <p>{workex.description}</p>
                                        <button className="primary-button">DEMO</button>
                                    </div> */}
                            </div>
                        );
                    })}
                </div>
            </div>


        </div>
    )
}
export default Aboutme