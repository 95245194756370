import React from 'react'
import Carousel from '../components/Carousel'
import Header from '../components/Header'
import { FaRegGrinWink } from 'react-icons/fa'
import { BsBookHalf } from 'react-icons/bs'
import { Link } from 'react-router-dom'


function Home() {
    const path = window.location.pathname

    return (
        <div>
            <Header />
            <div className="introduction flex-with-center" style={{ backgroundImage: `url('./images/introbg.svg')` }}>
                <div>
                    <h1>DEEPAK</h1>
                    <div className="intro-content d-flex justify-content-between">
                        <p> Engineer | Problem Solver | Free Software Hacker</p>
                        {/* <button className='primary-button font-bold'>Click Me!</button> */}
                    </div>
                    <div className="intro-content d-flex justify-content-between">
                            <p className={`${path==='/aboutme' && 'active'}`}> 
                                <Link to='/aboutme'>More About me!</Link>
                            </p>
                            <p className={`${path==='/projects' && 'active'}`}>
                                <Link to='/projects'>Projects & Open Source</Link>
                            </p>
                    </div>
                </div>
            </div>

            <Carousel />

            {/* getwaves.io wave svg */}
            <span>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
                    <path fill="#001220" fill-opacity="1" d="M0,160L24,181.3C48,203,96,245,144,261.3C192,277,240,267,288,234.7C336,203,384,149,432,149.3C480,149,528,203,576,218.7C624,235,672,213,720,181.3C768,149,816,107,864,96C912,85,960,107,1008,112C1056,117,1104,107,1152,133.3C1200,160,1248,224,1296,213.3C1344,203,1392,117,1416,74.7L1440,32L1440,0L1416,0C1392,0,1344,0,1296,0C1248,0,1200,0,1152,0C1104,0,1056,0,1008,0C960,0,912,0,864,0C816,0,768,0,720,0C672,0,624,0,576,0C528,0,480,0,432,0C384,0,336,0,288,0C240,0,192,0,144,0C96,0,48,0,24,0L0,0Z">
                    </path>
                </svg>
            </span>

            {/* staircase content */}
            {/* Todo - either add me or remove me ! <Staircase /> */}

            {/* Tech-Stack */}
            <div className='devstack container n-box2 py-5 my-5'>

                <div className='text-center'>
                    <h3 className='font-bold '> My Stack </h3>
                    <hr />
                    <img src="./images/developer.svg" alt="" height='200' width='200' />
                </div>

                <div className='row'>
                    <div className='col-md-4'>
                        <div className='font-bold'>
                            <div>
                                <h3 className='font-bold'>Front End</h3>
                                <hr />
                                <p>React</p>
                                {/* <p>web3.js</p> */}
                                <p>Bootstrap</p>
                                <p>Truffle</p>
                                <p>NodeJS</p>

                            </div>
                        </div>
                    </div>

                    <div className='col-md-4'>
                        <div className='font-bold text-center'>
                            <div>
                                <h3 className='font-bold'>Backend</h3>
                                <hr />
                                <p>Golang * Java * Solidity * Python</p>
                                <p>Ethereum * Smart Contracts * DApps * IPFS</p>
                                <p>OWASP * XSS * Web Application Pentesting</p>
                                <p>AWS * GCP * Azure</p>
                                <p>Kubernetes * Docker</p>

                            </div>
                        </div>
                    </div>

                    <div className='col-md-4'>
                        <div className='font-bold text-end'>
                            <div>
                                <h3 className='font-bold'>Everything else <FaRegGrinWink/> </h3>
                                <hr />
                                <p>Project Management</p>
                                <p>Security Ambassador</p>
                                {/* <p>DevSecOps</p> */}
                                <p>TDD * Hexagonal * DDD * SOLID</p>
                                <p>Leadership and Ownership</p>
                                <p>Always Learning <BsBookHalf /> </p>

                            </div>
                        </div>
                    </div>

                </div>
            </div>

        </div>
    )
}

export default Home