import React , {useState} from 'react'
import { BsMenuUp } from 'react-icons/bs'
import { RiCloseFill } from 'react-icons/ri'
import { Link } from 'react-router-dom'

export default function Header() {
    
    const[showHeader, setShowHeader] = useState(false)
    const path = window.location.pathname
    return (
        <div className='header'>
            {showHeader ? (
                <RiCloseFill 
                onClick={()=>{setShowHeader(!showHeader)}}
                className='menu-icon position-fixed top-0 end-0'/>
            ) : (
                <BsMenuUp 
                onClick={()=>{setShowHeader(!showHeader)}}
                className='menu-icon position-fixed top-0 end-0'/>
            )}

            <ul className={`${showHeader ? 'show-header' : 'hide-header'} n-box1`}>
                <li className={`${path==='/' && 'active'}`}><Link to='/'>Home</Link></li>
                <li className={`${path==='/aboutme' && 'active'}`}><Link to='/aboutme'>More About me!</Link></li>
                <li className={`${path==='/projects' && 'active'}`}><Link to='/projects'>Projects & Open Source</Link></li>
                {/* <li className={`${path==='/contact' && 'active'}`}><Link to='/contact'>Contact</Link></li> */}
            </ul>
 
            
        </div>
    )
}
