const workexlist = [
    {
        "name": "Striim ",
        "site": "striim.com ",
        "from": "2015",
        "to": "present ",
        "position": "Senior Software Engineer"  
    },
    {
        "name": "Apptio",
        "site": "apptio.com",
        "from": "2013 ",
        "to":  "2015",
        "position": "Senior Software Engineer"  
     },
    {
        "name": "Amazon ",
        "site": "amazon.com ",
        "from": "2012",
        "to": "2013",
        "position": "SDE 2"  
     },
    {
        "name": "Citrix ",
        "site": "amazon.com ",
        "from": "2011",
        "to":  "2012",
        "position": "Software Engineer"  
    },
];

export default workexlist;
