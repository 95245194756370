import React from 'react';
import Header from '../components/Header';
import './projects.css';
// import ProjectItems from '../components/ProjectItems'

function Projects() {
    return (
        <div>
            <Header />
            <div class="projectWrapper">
                <div class="projectItems">
                    <img
                        class="imageStyle"
                        src="https://naveengarg.dev/img/portfolio/thumbnails/crypto3-square-final.jpg"
                    />
                    <a href="https://cryptocoinnews.herokuapp.com">
                        <p class="portfoliodesctitle">CRYPTO NEWS NETWORK</p>
                    </a>
                    <p class="portfolioDescription">
                        Crypto news website build using Bootstrap, Django and cryptocompare API from
                        www.cryptocompare.com
                    </p>
                    <div class="imageOverlay"></div>
                </div>

                <div class="projectItems">
                    <img
                        class="imageStyle"
                        src="https://universoabiertoblog.files.wordpress.com/2015/12/openstack-logo-rgb.png"
                    />
                    <a href="https://review.openstack.org/#/q/deepakgarg.iitg%2540gmail.com,n,z">
                        <p class="portfoliodesctitle">OpenStack PRs</p>
                    </a>
                    <p class="portfolioDescription">
                        My contributions to the OpenStack IaaS project
                    </p>
                    <div class="imageOverlay"></div>
                </div>

                <div class="projectItems">
                    <img
                        class="imageStyle"
                        src="https://blogs.apache.org/cloudstack/mediaresource/ab378739-3c34-48ea-9495-2c49e23e58d6"
                    />
                    <a href="https://reviews.apache.org/users/deepak/">
                        <p class="portfoliodesctitle">CloudStack PRs</p>
                    </a>
                    <p class="portfolioDescription">
                        My contributions to the Apache CloudStack project
                    </p>
                    <div class="imageOverlay"></div>
                </div>

                <div class="projectItems">
                    <img
                        class="imageStyle"
                        src="https://cdn.slidesharecdn.com/ss_thumbnails/xenoverviewq22009sp-090501152602-phpapp01-thumbnail-4.jpg?cb=1241191575"
                    />
                    <a href="https://blueprints.launchpad.net/nova/+spec/xenapi-provider-firewall">
                        <p class="portfoliodesctitle">XenAPI Provider Firewall Spec</p>
                    </a>
                    <p class="portfolioDescription">
                        My spec and implementation for the Provider Firewall implementation for Xen based hypervisors
                    </p>
                    <div class="imageOverlay"></div>
                </div>

                <div class="projectItems">
                    <img
                        class="imageStyle"
                        src="https://www.datamounts.com/wp-content/uploads/2019/10/What-is-AutoScaling.png"
                    />
                    <a href="https://cwiki.apache.org/confluence/display/CLOUDSTACK/Autoscaling">
                        <p class="portfoliodesctitle">CloudStack Autoscaling Spec</p>
                    </a>
                    <p class="portfolioDescription">
                        My spec and implementation for the Autoscaling feature in Apache CloudStack
                    </p>
                    <div class="imageOverlay"></div>
                </div>

                <div class="projectItems">
                    <img
                        class="imageStyle"
                        src="https://upload.wikimedia.org/wikipedia/commons/1/18/Ipfs-logo-1024-ice-text.png"
                    />
                    <a href="https://github.com/ipfs/go-ipfs-cmds/pulls?q=is%3Apr+author%3Agargdeepak+is%3Aclosed">
                        <p class="portfoliodesctitle">IPFS</p>
                    </a>
                    <a href="https://github.com/ipfs/ipfs-cluster/pulls?q=is%3Apr+author%3Agargdeepak+is%3Aclosed">
                        <p class="portfoliodesctitle">IPFS Cluster</p>
                    </a>
                    <div class="imageOverlay"></div>
                </div>

                <div class="projectItems">
                    <img
                        class="imageStyle"
                        src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRqFaI00VpDBfYm0OTdB7HqJtGHVhrgVatzT7MKFtSIIan4yZ5L7uQkRk1H79UaXm0UE3k&usqp=CAU"
                    />
                    <a href="https://sites.google.com/site/sharedcodes/">
                        <p class="portfoliodesctitle">C++ and Gnuplot src for Numerical analysis</p>
                    </a>
                    <p class="portfolioDescription">
                        Numerical analysis code sharing back in college :)
                    </p>
                    <div class="imageOverlay"></div>
                </div>

            </div>

        </div>
    );
}
export default Projects;
