import React from 'react'
import './workex.css';

function WorkEx(props){
    return (
        <div className='flex-with-center who-is-deepak whereHaveIWorked'>
            <div class="whereDidIWorkDetails">
                <h3 className='font-bold text-center'>
                    {props.data.name} 
                </h3>
                <p>
                    <pre>{
                        JSON.stringify(
                            props.data,
                        null,
                        2
                    )}
                    </pre>
                </p>
            </div>
        </div>
    )
}
export default WorkEx